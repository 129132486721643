<template>
  <section
    class="flex flex-col w-screen min-h-screen bg-gray-100 page-public-main"
  >
    <img
      src="../../../assets/images/main/head.jpg"
      class="w-full"
    >
    <div class="py-3 -mt-3 -mb-2 bg-gray-100 rounded-xl" />
    <div
      v-for="route of publicMenuitems"
      :key="`route-${route.id}`"
      class="mx-3 mb-3"
    >
      <h3 class="my-3 font-normal text-gray-600">
        {{ route.name }}
      </h3>
      <van-grid
        column-num="4"
        square
      >
        <template v-for="item in route.children">
          <van-grid-item
            v-if="item.miniapp"
            :key="`miniapp-${item.id}`"
            class="verified-only"
            :class="{
              'disabled-grid-item': item.disabled
            }"
          >
            <template #default>
              <div class="relative flex flex-col items-center justify-center">
                <div
                  class="cr cr-top cr-right cr-green"
                  style="top: -8px;right: -32px;"
                >
                  小程序
                </div>
                <div class="mb-2 text-2xl">
                  <i
                    class="fal"
                    :class="item.icon"
                  />
                </div>
                <div
                  :inner-html.prop="item.title | htmlXss"
                  :class="item.className ? item.className : 'text-sm'"
                />
                <div class="absolute top-0 bottom-0 left-0 right-0 opacity-0">
                  <wx-open-launch-weapp
                    :id="`weapp-${item.id}`"
                    :username="item.miniapp.username"
                    :path="item.miniapp.path"
                  >
                    <script type="text/wxtag-template">
                      <style>.btn { height: 100%; width: 100%; opacity: 0;}</style>
                      <button class="btn">打开小程序</button>
                    </script>
                  </wx-open-launch-weapp>
                </div>
              </div>
            </template>
          </van-grid-item>
          <van-grid-item
            v-else
            :key="`other-${item.id}`"
            class=""
            :class="{
              'verified-only': item.verifiedOnly,
              'disabled-grid-item': item.disabled
            }"
            @click="onClicked(item)"
          >
            <template #icon>
              <div
                v-if="item.verifiedOnly"
                class="cr cr-top cr-right cr-red"
              >
                业主认证
              </div>
              <div class="mb-2 text-2xl">
                <i
                  class="fal"
                  :class="item.icon"
                />
              </div>
            </template>
            <template #text>
              <div
                :inner-html.prop="item.title | htmlXss"
                :class="item.className ? item.className : 'text-sm'"
              />
            </template>
          </van-grid-item>
        </template>
      </van-grid>
    </div>
  </section>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import share from '@/mixins/share'
export default {
  name: 'PublicMain',
  mixins: [share],
  computed: {
    ...mapState('Common/Menu', ['publicMenuitems']),
    ...mapGetters('Common/Credential', ['userInfo'])
  },
  mounted() {
    this.disableShareMenu()
  },
  methods: {
    onClicked(item) {
      if (item.disabled) {
        // eslint-disable-next-line no-console
        console.warn('onDevelopment')
        this.$toast('开发中...')
        return
      }
      if (item.verifiedOnly) {
        let authenticated = ['已认证'].includes(this.userInfo?.status)
        if (!authenticated) {
          this.$dialog
            .confirm({
              title: '此功能仅向业主开放',
              message: '您尚未进行业主认证，是否立即进行业主认证？',
              theme: 'round-button',
              showCancelButton: true,
              cancelButtonText: '暂时不了',
              confirmButtonText: '业主认证'
            })
            .then(() => {
              this.$router.push({
                name: 'public.authentication'
              })
            })
            .catch(() => {})
          return
        }
      }
      if (item.link) {
        window.location.href = item.link
        return
      }
      // eslint-disable-next-line no-console
      console.warn(item.route)
      this.$router.push(item.route)
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled-grid-item {
  color: #ccc;
}
.verified-only {
  position: relative;
  overflow: hidden;
  .cr {
    font-size: 8px;
    width: 70px;
    padding: 0;
    position: absolute;
    text-align: center;
    color: #f0f0f0;
    font-weight: 400;
  }

  /* Positions */

  .cr-top {
    top: -8px;
  }
  .cr-bottom {
    bottom: 25px;
  }
  .cr-left {
    left: -50px;
  }
  .cr-right {
    right: -55px;
  }

  /* Rotations */

  .cr-top.cr-left,
  .cr-bottom.cr-right {
    transform: rotate(-45deg);
  }

  .cr-top.cr-right,
  .cr-bottom.cr-left {
    transform: rotate(45deg);
  }

  .cr-red {
    background-color: #e43;
  }
  .cr-green {
    background-color: rgb(18, 131, 12);
  }
}
</style>
